import {
  chakra,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Card,
  Heading,
  Hide,
  HStack,
  Stack,
  Step,
  StepIndicator,
  Stepper,
  StepSeparator,
  StepTitle,
  Tooltip,
  Text,
  Center,
  Spinner
} from '@chakra-ui/react'
import { useLocation } from 'wouter'

import { InfoOutlineIcon } from '@chakra-ui/icons'

import { useCallback } from 'react'

import { Page } from '../../components/Page'
import { useQuery } from '../../hooks'
import { setQueryParam } from '../../util/location'
import { cleanInteger } from '../../util/numbers'

import { DsbomComparisonImageReferences } from './DsbomComparisonImageReferences'
import { DsbomComparisonResults } from './DsbomComparisonResults'
import { DsbomComparisonWhy } from './DsbomComparisonWhy'
import { DsbomComparisonFileSection } from './DsbomComparisonFileSection'
import { generateUploadFilesStepTitle } from './utils'
import { DsbomComparisonStep } from './DsbomComparisonStep'
import { DsbomComparisonFileTypeStep } from './DsbomComparisonFileTypeStep'

interface Props {
  workspaceSlug: string
}

export function DsbomComparisonPage({ workspaceSlug }: Props) {
  const [location, setLocation] = useLocation()
  const queryParams = useQuery()

  const inputs = queryParams.get('input')
  const inputList = inputs ? inputs.split(',') : []
  const title = generateUploadFilesStepTitle(inputList)

  const activeStep = cleanInteger(queryParams.get('step'), 0)
  const selectedInputs = queryParams.get('input')
  const selectedImageReference = queryParams.get('selectedImageReference')

  const steps = [
    { title: 'Specify SBOM/SCA Tool(s)' },
    { title: 'Select a Comparison Image' },
    { title: 'Upload SBOM/SCA File(s)' },
    { title: 'View First DSBOM Report' }
  ]

  const setActiveStep = useCallback(
    (index: number) => {
      // The function is called before the hook is triggered for the queryParams to change
      const urlParams = new URLSearchParams(window.location.search)
      setLocation(setQueryParam({ location, queryParams: urlParams, param: 'step', value: index.toString() }))
    },
    [location, setLocation]
  )

  const tooltipText =
    'If you are not sure how to download your SBOM/SCA report(s), we provide an example in our docs using Syft and Grype to download an SPDX-Formatted SBOM report and CycloneDX-Formatted SCA report.'

  return (
    <Page key={workspaceSlug}>
      <Stack spacing={4} alignItems="center">
        <HStack marginBottom={6}>
          <Heading size="xl" fontWeight="bold">
            Dynamic SBOM
          </Heading>
        </HStack>
        <DsbomComparisonWhy />
        <Card width="full" maxWidth="1280px">
          <Stack spacing={4}>
            <Heading size="md" textAlign="center" margin={8}>
              Setting Up Your First Dynamic SBOM
            </Heading>
            <Hide below="2xl">
              <Stepper index={activeStep} marginX={16}>
                {steps.map((step, index) => (
                  <Step key={index}>
                    <Box flexShrink="0">
                      <StepTitle>{step.title}</StepTitle>
                    </Box>
                  </Step>
                ))}
              </Stepper>
            </Hide>
            <Stepper index={activeStep} marginX={{ base: 8, xl: 36 }}>
              {steps.map((step, index) => (
                <Step key={index} style={{ gap: 0 }}>
                  <Tooltip label={step.title}>
                    <chakra.div>
                      <StepIndicator color="brand" borderWidth={4} fill="transparent" />
                    </chakra.div>
                  </Tooltip>
                  <StepSeparator />
                </Step>
              ))}
            </Stepper>
            {activeStep !== 3 && (
              <Accordion index={activeStep} marginTop={8}>
                <DsbomComparisonStep
                  index={0}
                  title="1. Specify your SBOM tool(s)"
                  nextValidator={() => selectedInputs === null}
                  previousValidator={() => true}
                  marginTop="4"
                >
                  <DsbomComparisonFileTypeStep />
                </DsbomComparisonStep>
                <DsbomComparisonStep
                  index={1}
                  title="2. Select a profiled image to compare with your SBOM"
                  nextValidator={() => selectedImageReference === null}
                  previousValidator={() => true}
                >
                  <DsbomComparisonImageReferences workspaceSlug={workspaceSlug} />
                </DsbomComparisonStep>
                <AccordionItem paddingX={6} paddingY={4}>
                  <AccordionButton color="faded" _expanded={{ color: 'primaryText' }}>
                    <Text>3. {inputList.length > 0 ? title : 'Please upload your files.'}</Text>
                    <Tooltip label={tooltipText} fontSize="md">
                      <InfoOutlineIcon marginLeft="1" />
                    </Tooltip>
                  </AccordionButton>
                  <AccordionPanel>
                    <Stack alignItems="center">
                      <DsbomComparisonFileSection workspaceSlug={workspaceSlug} onBack={() => setActiveStep(1)} onNext={() => setActiveStep(3)} />
                    </Stack>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem paddingX={6} paddingY={4}>
                  <AccordionButton color="faded" _expanded={{ color: 'primaryText' }}>
                    <Text>4. Wait for Mayhem to run the comparison and view your DSBOM!</Text>
                    <Tooltip label={tooltipText} fontSize="md">
                      <InfoOutlineIcon marginLeft="1" />
                    </Tooltip>
                  </AccordionButton>
                  <AccordionPanel>
                    <Center>
                      <Spinner />
                    </Center>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            )}
            {activeStep === 3 && <DsbomComparisonResults workspaceSlug={workspaceSlug} />}
          </Stack>
        </Card>
      </Stack>
    </Page>
  )
}
