import { Link as WouterLink } from 'wouter'
import { Stack } from '@chakra-ui/react'

import ArrowBackIcon from '@material-design-icons/svg/sharp/arrow_back.svg?react'

import ProjectsIcon from '../../images/nav-projects.svg?react'

import { useGetProjectMemberPermissionQuery } from '../../redux/api/projects'

import { getCurrentUserInfo } from '../auth/utils'
import { NavButton } from '../../components/Shell/NavButton'
import { useAppSelector } from '../../hooks'

import { MenuSection, SubnavSectionHeader } from './WorkspaceSidebar'
import { getSelectedRoute } from './utils'

export const ProjectMenu = ({ workspaceSlug, projectSlug }: ProjectMenuProps) => {
  const { userSlug, isAdmin: isMayhemAdmin } = useAppSelector((state) => getCurrentUserInfo(state) || {})
  const { isLoading: projectMemberPermissionIsLoading, data: projectPermission } = useGetProjectMemberPermissionQuery(
    {
      owner: workspaceSlug,
      projectSlug: projectSlug,
      userSlug
    },
    { skip: !userSlug }
  )

  const isProjectAdmin = isMayhemAdmin || (!projectMemberPermissionIsLoading && projectPermission && projectPermission.permission === 'ADMIN')

  const sections: MenuSection[] = [
    {
      name: projectSlug,
      icon: ProjectsIcon,
      routes: [
        {
          path: `/${workspaceSlug}/${projectSlug}`,
          name: 'Dashboard',
          key: 'overview',
          extraPathMatches: [`/${workspaceSlug}/${projectSlug}/:targetSlug`, `/${workspaceSlug}/${projectSlug}/:targetSlug/-/settings/general`]
        },
        {
          path: `/${workspaceSlug}/${projectSlug}/-/defects`,
          name: 'Defects',
          key: 'defects',
          extraPathMatches: [`/${workspaceSlug}/${projectSlug}/:targetSlug/-/defects/*?`]
        },
        {
          path: `/${workspaceSlug}/${projectSlug}/-/runs`,
          name: 'Runs',
          key: 'runs',
          extraPathMatches: [`/${workspaceSlug}/${projectSlug}/:targetSlug/:run/*?`]
        },
        {
          path: `/${workspaceSlug}/${projectSlug}/-/builds`,
          name: 'Builds',
          key: 'builds',
          extraPathMatches: [`/${workspaceSlug}/${projectSlug}/-/builds/*?`]
        }
      ],
      isShown: true
    },
    {
      name: 'Project Settings',
      routes: [
        { path: `/${workspaceSlug}/${projectSlug}/-/settings/general`, name: 'General', key: 'settings' },
        { path: `/${workspaceSlug}/${projectSlug}/-/settings/access-control`, name: 'Access Control', key: 'access control' },
        { path: `/${workspaceSlug}/${projectSlug}/-/settings/webhooks`, name: 'Webhooks', key: 'webhooks' }
      ],
      isShown: !!isProjectAdmin
    }
  ]

  const selectedRoute = getSelectedRoute(sections)

  return (
    <Stack spacing="1">
      <WouterLink to={`/${workspaceSlug}/-/projects`}>
        <NavButton label="All projects" icon={ArrowBackIcon} marginBottom={4} marginTop={3} />
      </WouterLink>
      {sections.map(
        (section) =>
          section.isShown && (
            <>
              <SubnavSectionHeader name={section.name} color={section.name !== 'Project Settings' ? 'primaryText' : undefined} />
              {section.routes.map((route) => (
                <WouterLink key={route.key} to={route.path}>
                  <NavButton label={route.name} isActive={selectedRoute?.key === route.key} />
                </WouterLink>
              ))}
            </>
          )
      )}
    </Stack>
  )
}

export interface ProjectMenuProps {
  workspaceSlug: string
  projectSlug: string
}
