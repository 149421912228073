import { ComponentStyleConfig } from '@chakra-ui/react'
import { StyleFunctionProps } from '@chakra-ui/theme-tools'

import { theme } from '../theme'

export const Button: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: 'normal',
    borderRadius: 8
  },
  sizes: {
    md: {
      height: 9
    }
  },
  variants: {
    solid: (props: StyleFunctionProps) => {
      const { colorScheme } = props
      // for the 'gray' colorscheme, stick with what Chakra uses for colors, otherwise use the
      // same colors for both light and dark mode (as per the design system)
      return {
        bg: colorScheme === 'gray' ? undefined : `${colorScheme}.500`,
        color: 'white',
        _hover: {
          bg: colorScheme === 'gray' ? undefined : `${colorScheme}.600`
        },
        _active: {
          bg: colorScheme === 'gray' ? undefined : `${colorScheme}.700`
        },
        paddingLeft: '0.7rem',
        paddingRight: '0.7rem'
      }
    },
    'filter-selected': () => ({
      backgroundColor: 'brand.100',
      color: 'brand.700',
      _hover: {
        backgroundColor: 'brand.200'
      },
      _active: {
        backgroundColor: 'brand.200'
      },
      _dark: {
        backgroundColor: 'brand.700',
        color: 'brand.300',
        _hover: {
          backgroundColor: 'brand.800'
        },
        _active: {
          backgroundColor: 'brand.200'
        }
      }
    }),
    secondary: (props: StyleFunctionProps) => ({
      ...theme.components.Button.variants.outline(props),
      borderColor: props.colorMode === 'dark' ? 'brand.50' : 'brand.500',
      color: props.colorMode === 'dark' ? 'brand.50' : 'brand.500',
      _hover: {
        bg: props.colorMode === 'dark' ? 'brand.200' : 'brand.50'
      }
    }),
    // for buttons on colored backgrounds (e.g. warning/error card headers)
    'outline-inverse': (props: StyleFunctionProps) => ({
      ...theme.components.Button.variants.outline(props),
      borderColor: 'body',
      color: 'body',
      _active: {
        background: props.colorMode === 'dark' ? 'blackAlpha.400' : 'whiteAlpha.400',
        color: props.colorMode === 'dark' ? 'body' : 'whiteAlpha.700'
      },
      _hover: {
        background: props.colorMode === 'dark' ? 'blackAlpha.600' : 'whiteAlpha.600',
        color: props.colorMode === 'dark' ? 'body' : 'whiteAlpha.900'
      }
    }),
    'sidenav-link': (props: StyleFunctionProps) => ({
      ...theme.components.Button.variants.ghost(props),
      color: 'primaryText',
      borderRadius: '2xl',
      fontWeight: 'medium',
      // when the sidenav link is being clicked
      _active: {
        background: 'activeBg',
        color: 'activeColor'
      },
      // when the sidenav link is the currently displayed page
      _activeLink: {
        background: 'selectedBg',
        color: 'selectedColor'
      },
      _hover: {
        background: 'hoverBg',
        color: 'hoverColor'
      }
    }),
    'row-button': (props: StyleFunctionProps) => ({
      ...theme.components.Button.variants.ghost(props),
      _hover: {
        color: props.colorMode === 'dark' ? 'brand.400' : 'brand.300'
      }
    })
  },
  defaultProps: {
    variant: 'solid',
    colorScheme: 'brand'
  }
}
